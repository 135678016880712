
import LoginForm from '../components/LoginForm.vue';
import { defineComponent } from 'vue';
import { asideTheme } from '@/core/helpers/config';

export default defineComponent({
    name: 'login',

    components: {
        LoginForm
    },
    setup() {
        return {
            asideTheme
        };
    }
});
